import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import './ResponsiveAppbar.css';
import './animatedButton.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';


const pages = [<a href="https://etherscan.io/token/0xbb04982ef748712eec1a49c64f030b4fe902612e">Etherscan</a>, <a href="https://raw.githubusercontent.com/AlexanderTheAstronaut/BadInvestmentCoinPDF/679cdeb74130f5ba9130c4766967f86017a886db/Bad%20Investment%20Coin%20Whitepaper.pdf" download>Whitepaper</a>, <a href="./Social">Social</a>];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const moment = require('moment-timezone');

const [expiryTime, setExpiryTime] = useState("06 apr 2024 20:00:00");
const [countdownTime, setCountdownTime] = useState({
  countdownDays: "",
  countdownHours: "",
  countdownlMinutes: "",
  countdownSeconds: "",
});

const countdownTimer = () => {
  const timeInterval = setInterval(() => {
    const countdownDateTime = moment.tz(expiryTime, 'DD MMM YYYY HH:mm:ss', 'America/Los_Angeles').valueOf();
    const currentTime = moment.tz('America/Los_Angeles').valueOf();
    const remainingDayTime = countdownDateTime - currentTime;
    const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
    const totalHours = Math.floor(
      (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const totalMinutes = Math.floor(
      (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
    );
    const totalSeconds = Math.floor(
      (remainingDayTime % (1000 * 60)) / 1000
    );

    const runningCountdownTime = {
      countdownDays: totalDays,
      countdownHours: totalHours,
      countdownMinutes: totalMinutes,
      countdownSeconds: totalSeconds,
    };

    setCountdownTime(runningCountdownTime);

    if (remainingDayTime < 0) {
      clearInterval(timeInterval);
      setExpiryTime(false);
    }
  }, 1000);
};
useEffect(() => {
  countdownTimer();
}, []);

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
      <Container maxWidth="100%">
        <Toolbar disableGutters>
          <a href="/"className="logoDiv" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}></a>
        

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon className="menuButton" />
            </IconButton>
            <Menu 
              id="menu-appbar"
              anchorEl={anchorElNav}
              href="./*"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip >
              <IconButton >
                <div><a href="https://app.uniswap.org/#/swap?&chain=mainnet&use=v2&outputCurrency=0xbb04982ef748712eec1a49c64f030b4fe902612e"className="animated-button1">
                <span></span>
  <span></span>
  <span></span>
  <span></span>
  Buy
                  </a></div>
              </IconButton>

            
              </Tooltip>
            
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;