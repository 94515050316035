import React from 'react';
import './Social.css';
import ResponsiveAppBar from './ResponsiveAppBar.js'

function Social() {
    return (
        <div className="Social-Header">
            <ResponsiveAppBar/>
            <div className="contentDiv">
                <a href="https://twitter.com/BInvestmentCoin" className="Twitter"><div className="Xlogo"></div></a>
                <a href="https://www.tiktok.com/@badinvestmentcoin" className="TikTok"><div className="Tlogo"></div></a>
                <a href="https://www.youtube.com/channel/UC5wIV1cPjXEteF2Fl23gk1A" className="Youtube"><div className="Ylogo"></div></a>
                <a href="https://www.instagram.com/badinvestmentcoin?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" className="Instagram"><div className="Instagramlogo"></div></a>
                <a href="https://discord.gg/Q7gxQeQU" className="discord"><div className="Dlogo"></div></a>
                <a href="https://t.me/badCoinEntry" className="telegram"><div className="Telogo"></div></a>
            </div>
        </div>
    )
}

export default Social
