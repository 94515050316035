import logo from './logo.svg';
import './App.css';
import ResponsiveAppBar from './ResponsiveAppBar.js';
import badShib from './BAD3Dog.png';
import React from 'react';
import { useTypewriter } from 'react-simple-typewriter';




function App() {
  const [typeEffect] = useTypewriter({
    words: [' a bad investment', ' the most unique way to waste your money', '  a memecoin', ' a ERC-20 token'],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40
  })
  return (
    <div className="App">
      <ResponsiveAppBar/>
      
      <div className="App-header">
        <div className="Intro">
          <div className="Shibacoin"><div className="shibShadow"><img className="bshib" src={badShib} alt='Bad Investment Coin Logo' /></div></div>
          <div className="typerDivDiv"><div className="typerDiv"> Bad Investment Coin is   
          <span>{typeEffect}</span>
           </div>
           </div>
        </div>
        <div className="ShibaDown"></div>
        <div className="founderDiv">
          <div className="founderText">
          <h1 className="founderTitle">A Message From The Founder</h1>
          <h6 className="founderDescription">I created Bad Investment Coin to create a unique way for individuals to waste their money. Most cryptocurrencies don't admit they are bad investments, but here I have done just that. My goal with Bad Investment Coin is for it to stand out in the ever-growing sea of meme coins.</h6>
          <a className="FounderLink" href="https://alexanderbuick.com/">Read more about the founder</a>
          </div>
          <div className="founderImage"></div>
        </div>
      </div>

    </div>
  );
}

export default App;
